import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import stylesOrig from './TabbedBrandsMobileMenu.scss';
import SubMenu from './TabbedBrandsMobileMenuSubMenu';
import filterMenuData from '../../../utils/menu/filterMenuData';
import { brandsData, servicesData, helpData } from './tabbedMenuData';
import FeedbackMenu from '../mobile-menu/FeedbackMenu';
import { getStaticMobileMenus } from '../../links';

const InspirationLink = {
  name: 'Inspiration',
  url: '/content',
  id: '10139580012',
  includedInMenus: ['MOBILE_WEB'],
};

const MobileMenu = ({
  menus: initialMenus = [],
  fetchChildCategories,
  isCleanMobileNavActive = false,
}) => {
  const styles = stylesOrig;
  const rootMenuName = initialMenus[0]?.name || '';

  const initialMenusFiltered = filterMenuData(
    initialMenus[0]?.child,
    'MOBILE_WEB'
  );

  const staticMenus = getStaticMobileMenus();

  const initialRootMenuItems = [
    ...(initialMenusFiltered || []),
    InspirationLink,
  ];

  // pull brands from initial menu data
  const brandsMenuItems = initialRootMenuItems.find(
    item => item.name === 'Brands'
  );

  // pull services from initial menu data
  const servicesMenuItems = initialRootMenuItems.find(
    item => item.name === 'Services'
  );

  const [rootMenuItems, setRootMenuItems] = useState(initialRootMenuItems);

  const top = useRef();
  const [activeLevel, setActiveLevel] = useState(0);

  const scrollToTop = () => {
    if (top.current && top.current.scrollBy) {
      top.current.scrollBy(0, -1000);
    }
  };

  const [activeTab, setActiveTab] = useState('Shop');

  useEffect(() => {
    let updatedSubMenuData = null;
    if (activeTab === 'Brands') {
      if (brandsMenuItems) {
        updatedSubMenuData = brandsMenuItems?.child;
      } else {
        updatedSubMenuData = brandsData?.child;
      }
    }
    if (activeTab === 'Services') {
      if (servicesMenuItems) {
        updatedSubMenuData = servicesMenuItems?.child;
      } else {
        updatedSubMenuData = servicesData?.child;
      }
    }
    if (activeTab === 'Help') {
      updatedSubMenuData = helpData?.child;
    }

    if (activeTab !== 'Shop') {
      setRootMenuItems(updatedSubMenuData);
    } else {
      // dont show brands or services in shop menu
      setRootMenuItems(
        initialRootMenuItems.filter(
          item => item.name !== 'Brands' && item.name !== 'Services'
        )
      );
    }
  }, [activeTab]);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchSubMenuData = async (menuItemId, menuIndex) => {
    if (rootMenuItems[menuIndex].isCached) {
      return;
    }

    try {
      const fetchedData = await fetchChildCategories({
        id: menuItemId,
      });

      if (mounted.current === false) {
        return;
      }

      if (!fetchedData || fetchedData.error) {
        throw new Error('Fetch failed');
      }

      setRootMenuItems(existingRootMenuItems =>
        existingRootMenuItems.map((existing, i) =>
          i === menuIndex
            ? {
                ...existing,
                child: fetchedData.child || [],
                isCached: true,
              }
            : existing
        )
      );
    } catch (e) {
      console.error(`fetch failed for category id: ${menuItemId}`);
    }
  };

  const activeMenuStyles = [
    styles.activeLevelRoot,
    styles.activeLevelFirst,
    styles.activeLevelSecond,
    styles.activeLevelThird,
  ];
  const subMenuClassName = classNames([
    styles.subMenu,
    activeMenuStyles[activeLevel],
  ]);

  return (
    <>
      <nav
        className={styles.mobileMenu}
        ref={top}
        data-testid="mobile-navigation-menu"
      >
        <div
          className={subMenuClassName}
          data-testid="mobile-navigation-container"
        >
          <SubMenu
            level={0}
            setActiveLevel={setActiveLevel}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            subMenuData={{
              name: rootMenuName,
              child: rootMenuItems,
              backLabel: undefined,
            }}
            onItemSelected={(menuItemId, menuIndex) => {
              fetchSubMenuData(menuItemId, menuIndex);
            }}
            scrollToTop={scrollToTop}
            isCleanMobileNavActive={isCleanMobileNavActive}
          >
            <div
              data-testid="mobile-menu-static-section"
              className={classNames(
                styles.staticSection,
                styles.feedbackMenuBottom
              )}
            >
              {staticMenus.map(
                menu =>
                  menu.feedback && (
                    <FeedbackMenu
                      key={menu.name}
                      menu={menu}
                      isCleanMobileNavActive={isCleanMobileNavActive}
                    />
                  )
              )}
            </div>
          </SubMenu>
        </div>
      </nav>
    </>
  );
};

MobileMenu.defaultProps = {
  isCleanMobileNavActive: false,
};

const itemShape = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
};
itemShape.child = PropTypes.arrayOf(PropTypes.shape(itemShape), null);

MobileMenu.propTypes = {
  menus: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
  fetchChildCategories: PropTypes.func.isRequired,
  isCleanMobileNavActive: PropTypes.bool,
};

export default MobileMenu;
